.services {
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 90vh;
    margin-top: 9rem;
    margin-bottom: 13rem;
}
.awesome {
    display: flex;
    flex-direction: column;
    position: relative;
}
.awesome >:nth-child(1){
    color: var(--black);
    font-size: 2.5rem;
    font-weight: bold;
}
.awesome >:nth-child(2){
    color: var(--orange);
    font-size: 2.5rem;
    font-weight: bold;
}
.awesome >:nth-child(3){
    color: var(--gray);
    font-size: 14px;
    margin-top: 1rem;
}
.s-button {
    width: 8rem;
    height: 2rem;
    margin-top: 3rem;
}
.cards {
    position: relative;
}
.cards >*{
    position: absolute;
}
/* Blur */
.s-blur {
    top: 13rem;
    left: -18rem;
}
.s-blur2 {
    top: 8rem;
    left: 14rem;
}

@media screen and (max-width: 480px) {
    .services {
        margin-top: 0;
        flex-direction: column;
        gap: 5rem;
        height: 66rem;
        padding: 0;
    }
    .cards {
        display: flex;
        flex-direction: column;
        gap: 17rem;
    }
    .cards>* {
        position: static;
    }
}